<template>
  <!-- prettier-ignore -->
  <div class="modal-promo modal fade hide-scrollbar px-0"
    id="modalPromo" tabindex="-1" ref="promoModal"
    aria-labelledby="modalPromo" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered
      flex-column m-0 m-md-auto"
      @touchstart="handleTouchStart" @touchmove="handleTouchMove">
      <div class="modal-content">
        <div class="modal-body p-0 text-center">
          <div class="modal-image row p-1">
            <div class="col d-flex justify-content-center">
              <img :src="promotions[promo.index].poster"
                :alt="promotions[promo.index].name" />
            </div>
          </div>
          <div class="modal-details position-relative
            justify-content-center row p-2">
            <button type="button" class="btn position-absolute p-0"
              data-bs-dismiss="modal" aria-label="Close">
                <span class="material-symbols-outlined">cancel</span>
            </button>
            <div>
              <h2 class="pt-2">{{ promotions[promo.index].name }}</h2>
              <p class="mt-1 modal-desc" v-html="promotions[promo.index].description"></p>
            </div>
          </div>
        </div>
        <div class="swipe text-center position-absolute">
            <span class="material-icons d-block mb-1">swipe</span> Swipe
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PromoModal",
  data() {
    return {
      xDown: null,
      yDown: null,
    };
  },
  props: {
    promotions: Array,
    promo: Object,
  },
  methods: {
    handleTouchStart(evt) {
      this.xDown = evt.touches[0].clientX;
      this.yDown = evt.touches[0].clientY;
    },
    handleTouchMove(evt) {
      if (!this.xDown || !this.yDown) {
        return;
      }
      let xUp = evt.touches[0].clientX;
      let yUp = evt.touches[0].clientY;

      let xDiff = this.xDown - xUp;
      let yDiff = this.yDown - yUp;

      if (Math.abs(xDiff) > Math.abs(yDiff)) {
        /*most significant*/
        if (xDiff > 0) {
          // Next promo
          if (this.promo.index < this.promotions.length - 1) {
            this.promo.index += 1;
          }
        } else {
          // Prev promo
          if (this.promo.index > 0) {
            this.promo.index -= 1;
          }
        }
      }
      /* reset values */
      this.xDown = null;
      this.yDown = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  &-dialog-centered {
    min-height: calc(100% - 0.5rem);
  }
  &-content {
    background: transparent;
    height: 100vh;
    overflow: hidden;
    border: 0;
    .swipe {
      color: var(--secondary-font-cl);
      right: 16px;
      bottom: 16px;
      font-size: $ft-xxs;
    }
  }
  &-body {
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  &-image {
    height: 75%;
    overflow: auto;
    &::-webkit-scrollbar {
      display: none;
    }
    img {
      width: 100%;
    }
  }
  &-details {
    min-height: 25%;
    background: var(--secondary-bg);
    border-radius: 25px 25px 0 0;
    .modal-desc {
      white-space: pre-line;
    }
    h2 {
      font-size: $ft-lg;
    }
    button {
      color: var(--primary-font-cl);
      width: 24px;
      top: 8px;
      right: 16px;
      font-size: 24px;
    }
    img {
      width: 80px;
    }
  }
}
.flicker-enter {
  animation: flicker 1s linear 1s infinite;
}

@keyframes flicker {
  0% {
    transform: opacity 0;
  }
  50% {
    transform: opacity 1;
  }
  100% {
    transform: opacity 0;
  }
}
</style>